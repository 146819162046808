$('.category-item').each(function(){
	var $this = $(this);
	var $switch = $this.find('.category-switch');
	var $title = $this.find('.category-title');

	$title.on('click', function(evt) {
		if (window.innerWidth < 992) {
			var isOpen = $this.hasClass('is-open');
	
			$('.category-item')
				.removeClass('is-open')
				.find('.category-switch')
				.text('+');
	
			if (isOpen) {
				$this.removeClass('is-open');
				$switch.text('+');
			} else {
				$this.addClass('is-open');
				$switch.text('-');
			}
		}
	})
});

exports.modules = 'category';