if ($('.results').length) {
	$('.results a').each(function(link) {
		$(this).click( function(e) {
			var $this = $(this);
			e.preventDefault();
			var targetURL = $this.attr('href');
			var text = '';
			
			$('#myModal .modal-title').text('查詢結果');
			$('#myModal .modal-body').html('<iframe class="iframe" src="'+targetURL+'"></iframe>');
			$('#myModal').modal('show').addClass('is-results');
		})
	})

	$('#myModal').on('hide.bs.modal', function(e) {
		$('#myModal').removeClass('is-results');
	});
}

exports.modules = 'results';