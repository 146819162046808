$(window).on('load',function(){
	$('.preload')
		.fadeOut('200')
		.queue(function() {
			$(this).remove().dequeue();
		});

	$('body').addClass('is-loaded');
})

exports.modules = 'preload';