$('.tab').each(function(){
	var $this = $(this);
	var $head = $(this).find('.tab-head');
	var $body = $(this).find('.tab-body');
	var count = 0;

	$head.children('li').on('click', function(evt){
		var target = $(this).attr('tab-target');
		$head.children('li').removeClass('is-active');
		$body.children('.tab-content')
			.removeClass('is-active');

		$(this).addClass('is-active');
		$body.children('.tab-content[tab="'+target+'"]')
			.addClass('is-active');
	});

	$head.children('li').each(function() {
		if ($(this).hasClass('is-active')) {
			count ++;
			$(this).click();
		}
	});

	if (count == 0) {
		$head.children('li:nth-child(1)').click();
	}
});
exports.modules = 'tab';