if ($('[name="searchForm"]').length) {
	$form = $('[name="searchForm"]');

	$datepicker.on('change', function() {
		dateArray = $(this).val().split('/');
		year = dateArray[0];
		month = dateArray[1];

		var url = './select-type.html'+ // change to get-type.php
							'?type1='+$form.find('[name="sub_category"]').val() +
							'&name='+$form.find('[name="name"]').val() +
							'&year='+year +
							'&month='+month;

		location.href= url;
	});
}

exports.modules = 'selectType';