var $body = $('body, html');
var $window = $(window);
var $linkPosition;

$('a[href^="#"]').each(function(){
	var $link = $(this);
	$linkPosition;

	$link.click(function(e){
		e.preventDefault();

		var $target = $($(this).attr('href'));
		$linkPosition = $target.offset().top;

		$body.animate({
			scrollTop: $linkPosition
		});
	});
});

exports.modules = 'hashtagMove';