var Chart = function(id) {
	this.id = id;
	this.chartType = '';
}
Chart.prototype.init = function(data) {
	this.chartType = data.type;

	if (data.item.length > 0) {
		data.item.sort(function(a,b) {return a[0] - b[0];});
	}

	switch(this.chartType) {
		case 'month-chart':
			var $this = $('#'+ this.id);
			if (data.item.length != 0) {
				$this.parents('.chart').addClass('has-yearList');
				this.generateYearList(data);
			}
			break;
		case 'all-chart':
			this.generateAllChart(data);
			break;
		default: break;
	}
	
	if (this.chartType !== 'all-chart') {
		this.generateDistroList(data);
		this.generateFunc(data);
		this.generateForm(data);
		this.generateChart(data);
		this.changeMetaTitle(data);
	}
}
Chart.prototype.generateAllChart = function(data) {	
	var _this = this;
	var dom = document.getElementById('chart');
	dom.style.height = '60vh';
	var chartCanvas = echarts.init(dom, null, {renderer: 'canvas'});
	var arr = [];
	Object.keys(data.item).map(function (key) {
		arr.push(data.item[key][1]);
		arr.push(data.item[key][2]);
		arr.push(data.item[key][3]);
	});
	arr = arr.filter(Number);

	var option = {
		grid: [{
			top: '5%',
			width: '85%',
			height: '83%',
			left: 'center',
			containLabel: true
		}],
    dataZoom: [
			{
				type: 'slider',
				show: true,
				xAxisIndex: [0],
				bottom: '5%',
				start: 0,
				end: 100,
				handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				handleSize: '80%',
				handleStyle: {
					color: 'rgba(65, 125, 187, 1)'
				}
			},
			{
				type: 'slider',
				show: true,
				yAxisIndex: [0],
				right: '5%',
				start: 0,
				end: 100,
				handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				handleSize: '80%',
				handleStyle: {
					color: 'rgba(65, 125, 187, 1)'
				}
			}
		],
		tooltip: {
			padding: 0,
			backgroundColor: 'rgba(0,0,0,0)',
			borderRadius: '0',
			trigger: 'axis',

			formatter: function (params, ticket, callback) {
				var value = params[0].value || 'NO DATA';
				var list = '';

				Object.keys(params).map(function(key, index){
					list +=
						'<li>'+
							'<span class="is-sub-title">'+
								'<span class="is-color" style="background: '+params[key].color+';"></span>'+
								params[key].seriesName +
							'</span>'+
							'<span class="is-value">'+ params[key].value +'</span>'+
						'</li>';
				})

				var res =
					'<div class="chart-tooltip">'+
						'<div class="is-title">' + params[0].name +'</div>' +
						'<div class="is-content">'+
							'<ul class="is-list">'+
								list
							'</ul>' +
						'</div>' +
					'</div>';
				return res;
			}
		},
		legend: {
			top: 0,
			left: 'center',
			data: ['Search CPC', 'Display CPC', 'Video CPV']
		}, 
		xAxis: {
			type: 'category',
			data: Object.keys(data.item).map(function (key) {
				return data.item[key][0];
			}),
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				rotate: 0
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
		},
		yAxis: {
			scale: true,
			min: function(){
				var min = Math.min.apply( null, arr );
				return Math.floor(min*0.1)*10;
			},
			max: function(){
				var max = Math.max.apply( null, arr );
				return Math.ceil(max*0.1)*10;
			},
			type: 'value',
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				interval: 0,
				lineHeight: 200
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
			splitLine: {
				show: true
			},
			triggerEvent: true,
			offset: '10'
		},
		series: [
			{
				name: 'Search CPC',
				data: Object.keys(data.item).map(function (key) {
					return data.item[key][1];
				}),
				type: 'line',
				itemStyle: {
					normal: {
						color: 'rgba(65, 125, 187, 0.7)',
						color0: 'rgba(0,0,0,0)',
						borderColor: 'rgba(65, 125, 187, 1)',
						borderWidth: 1
					}
				},
				markLine: {
					itemStyle: {
						normal: { 
							borderWidth: 1,
	
							lineStyle: { 
								type: 'dotted', 
								color: '#FF5147', 
								width: 2 
							}, 
						}
					},
					data: [
						[
							{
								name: '',
								x: '9%',
								yAxis: 100,
								symbol: 'none'
							},
							{
								x: '92%',
								yAxis: 100,
								symbol: 'none'
							}
						]
					]
				}
			},
			{
				name: 'Display CPC',
				data: Object.keys(data.item).map(function (key) {
					return data.item[key][2];
				}),
				type: 'line',
				itemStyle: {
					normal: {
						color: 'rgba(26, 147, 111, 0.7)',
						color0: 'rgba(0,0,0,0)',
						borderColor: 'rgba(26, 147, 111, 1)',
						borderWidth: 1
					}
				},
				markLine: {
					itemStyle: {
						normal: { 
							borderWidth: 1,
	
							lineStyle: { 
								type: 'dotted', 
								color: '#FF5147', 
								width: 2 
							}, 
						}
					},
					data: [
						[
							{
								name: '',
								x: '9%',
								yAxis: 100,
								symbol: 'none'
							},
							{
								x: '92%',
								yAxis: 100,
								symbol: 'none'
							}
						]
					]
				}
			},
			{
				name: 'Video CPV',
				data: Object.keys(data.item).map(function (key) {
					return data.item[key][3];
				}),
				type: 'line',
				itemStyle: {
					normal: {
						color: 'rgba(152, 59, 199, 0.7)',
						color0: 'rgba(0,0,0,0)',
						borderColor: 'rgba(152, 59, 199, 1)',
						borderWidth: 1
					}
				},
				markLine: {
					itemStyle: {
						normal: { 
							borderWidth: 1,
	
							lineStyle: { 
								type: 'dotted', 
								color: '#FF5147', 
								width: 2 
							}, 
						}
					},
					data: [
						[
							{
								name: '',
								x: '9%',
								yAxis: 100,
								symbol: 'none'
							},
							{
								x: '92%',
								yAxis: 100,
								symbol: 'none'
							}
						]
					]
				}
			}
		]
	};

	if (option && typeof option === "object") {
		chartCanvas.setOption(option, true);

		$(window).on('resize', function(){
			if(chartCanvas != null && chartCanvas != undefined){
					chartCanvas.resize();
			}
		});
	}
}
Chart.prototype.generateChart = function(data) {
	var _this = this;
	var dom = document.getElementById(this.id);
	var chartHeight = null;
	var rowHeight = null;

	if (window.innerWidth>480) {
		rowHeight = 58;
	} else {
		rowHeight = 76;
	}

	if (Object.keys(data.item).length) {
		chartHeight += Object.keys(data.item).length * rowHeight;
	}

	dom.setAttribute('style', 'height: ' + chartHeight + 'px;');

	var chartCanvas = echarts.init(dom, null, {renderer: 'svg'});

	var option = {
		grid: [{
			top: '0',
			width: '90%',
			height: chartHeight + 'px',
			left: 'center',
			containLabel: true
		}],
		tooltip: {
			padding: 0,
			backgroundColor: 'rgba(0,0,0,0)',
			borderRadius: '0',
			trigger: 'axis',
			formatter: function (params, ticket, callback) {
				var res =
					'<div class="chart-tooltip">'+
						'<div class="is-title">' + params[0].name.split('\n').join('') + ' ' + params[0].seriesName + '</div>' +
						'<div class="is-content">'+
							'<ul class="is-list">'+
								'<li><span class="is-sub-title">'+params[1].seriesName+'</span><span class="is-value">'+params[1].data+'</span></li>'+
								'<li><span class="is-sub-title">25%~75%</span><span class="is-value">'+params[0].data[1]+' ~ '+params[0].data[2]+'</span></li>'+
								// '<li><span class="is-sub-title">10%~90%</span><span class="is-value">'+params[0].data[3]+' ~ '+params[0].data[4]+'</span></li>'+
							'</ul>' +
						'</div>' +
					'</div>';
				return res;
			}
		},
		xAxis: {
			type: 'value',
			silent: true,
			position: 'top',
			splitLine: {
				show: true
			},
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				rotate: 0
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
		},
		yAxis: [{
			type: 'category',
			data: Object.keys(data.item).map(function (key) {
				var str;
				switch(_this.chartType) {
					case 'month-chart':
						str = data.item[key][2];
						break;
					case 'year-chart':
						str = data.item[key][0];
						break;
					default: break;
				}
				return str;
			}).reverse(),
			axisLabel: {
				color: '#7A7A7A',
				fontSize: 14,
				interval: 0,
				lineHeight: 200
			},
			axisLine: {
				lineStyle: {
					color: '#CECECE'
				}
			},
			splitLine: {
				show: true
			},
			triggerEvent: true,
			offset: '10'
		}],
		series: [{
			name: '客戶落點',
			type: 'k',
			itemStyle: {
				normal: {
					color: 'rgba(65, 125, 187, 0.3)',
					color0: 'rgba(0,0,0,0)',
					borderColor: 'rgba(65, 125, 187, 0.8)',
					borderWidth: 1
				}
			},
			data: Object.keys(data.item).map(function (key) {
				var arr;

				switch(_this.chartType) {
					case 'month-chart':
						arr = [data.item[key][4], data.item[key][6],
									data.item[key][4], data.item[key][6] ]
						break;
					case 'year-chart':
						arr = [data.item[key][2], data.item[key][4],
									data.item[key][2], data.item[key][4] ]
						break;
					default: break;
				}
				return arr;
			}).reverse()
		},
		{
			name: '平均值',
			type: 'scatter',
			z: 2,
			itemStyle: {
				normal: {
					color: 'rgba(65, 125, 187, 1)'
				}
			},
			symbolSize: 10,
			data: Object.keys(data.item).map(function (key) {
				var str;
				switch(_this.chartType) {
					case 'month-chart':
						str = data.item[key][5];
						break;
					case 'year-chart':
						str = data.item[key][3];
						break;
					default: break;
				}
				return str;
			}).reverse()
		}]
	};

	if (option && typeof option === "object") {
		chartCanvas.setOption(option, true);

		$(window).on('resize', function(){
			if(chartCanvas != null && chartCanvas != undefined){
					chartCanvas.resize();
			}
		});
	}
}
Chart.prototype.generateYearList = function(data) {
	var list = $('.chart-list.is-yearList');
	var str = '';
	var month = (data.date[1]<10)? '0' + data.date[1] : data.date[1];

	for (var i=0; i<data.item.length; i++) {
		str +=
			'<li>'+
				'<a class="icon is-yearChart" href="'+
					'year-chart.html'+ // change to year-data.php
					'?duration='+data.date[0]+'-'+month+
					'&category='+data.category[0]+
					'&sub_category='+data.sub_category[0]+
					'&campaign1='+data.campaign[0]+
					'&campaign2='+data.campaign[1]+
					'&tag='+data.item[i][1]+
					'&name='+data.sub_category[1]+
				'">'+
					data.item[i][2].split('\n').join('')+' 年度趨勢'+
				'</a>'+
			'</li>';
	}

	list.html(str);
}
Chart.prototype.generateDistroList = function(data) {
	var list = $('.chart-list.is-distro');
	var str = '';
	var year, month, label, title;

	for (var i=0; i<data.item.length; i++) {
		switch (this.chartType) {
			case 'month-chart':
				year = data.date[0];
				month = (data.date[1]<10)? '0'+data.date[1] : data.date[1];
				label = data.item[i][1];
				title = data.item[i][2].split('\n').join('');
				break;
			case 'year-chart':
				year = data.duration.end[0];
				month = data.item[i][0].split('-')[1];
				label = data.tag[0];
				title = data.tag[1];
				break;
			default:
				break;
		}

		str += '<li>'+
							'<button class="icon is-distroChart getDistro" '+
								'data-year="'+year+'" '+
								'data-month="'+month+'" '+
								'data-category="'+data.category[0]+'" '+
								'data-sub_category="'+data.sub_category[0]+'" '+
								'data-campaign1="'+data.campaign[0]+'" '+
								'data-campaign2="'+data.campaign[1]+'" '+
								'data-label="'+label+'">'+title+' 分配圖'+
							'</button>'+
						'</li>';
	}
	
	list.html(str);

	$.each($('button.getDistro'), function(){
		$(this).on('click', function(){
			fetchDistroData($(this), $(this).data());
		});
	});

	function fetchDistroData(target, data) {
		$('#myModal').modal('show');

		url = 'distro.json'+ // change to Get_apd.php
					'?duration='+data.year+'-'+data.month+
					'&sub_category='+data.sub_category+
					'&campaign1='+data.campaign1+
					'&campaign2='+data.campaign2+
					'&label='+data.label;

		$.get(url)
			.done(function(data){
				if (typeof data == "string") var data = $.parseJSON(data);

				console.log('Distro Data', data);

				$('.modal-title').text(
					data.category[1] +' > '+
					data.sub_category[1]+' '+
					data.campaign[0]+' '+
					data.campaign[1]+' '+
					data.tag[1]+' 分配圖 '+
					'('+data.date[0]+'-'+data.date[1]+')'
				);

				if(data.item !== null) {
					showDistroChart(data);
				} else {
					$('.modal-body').html('<h3>NO DATA</h3>');
				}
			})
			.fail(function(err){
				$('.modal-title').text('Error');
				$('.modal-body').html('<h3>NO DATA</h3>');
			});
	}

	function showDistroChart(data) {
		if (!$('#distroChart').length) $('.modal-body').html('<div id="distroChart"></div>');
		var dom = document.getElementById('distroChart');
		
		if (!distroChartCanvas) var distroChartCanvas = echarts.init(dom, null, {renderer: 'canvas'});
		
		$(window).on('resize', function(){
			if(distroChartCanvas != null && distroChartCanvas != undefined){
					distroChartCanvas.resize();
			}
		});

		if (data.item.length > 0) var sortData = data.item.sort(function(a,b) {return a[0] - b[0];});

		distroChartCanvas.setOption({
			xAxis: {
				type: 'category',
				data: Object.keys(sortData).map(function (key) {
					num = new Number(data.item[key][0]);
					if (data.campaign[1] == 'CTR' || data.campaign[1] == 'VTR') {
						num = num.toFixed(4) * 100;
					}
					num = num.toFixed(2);
					return num;
				}),
				axisLabel: {
					color: '#7A7A7A',
					fontSize: 14,
					rotate: 0,
					formatter: function(value, index) {
						var str = '';
						if (data.campaign[1] == 'CTR' || data.campaign[1] == 'VTR') {
							str = value + '%';
						} else {
							str = '$' + value;
						}
						return str;
					}
				},
				axisLine: {
					lineStyle: {
						color: '#CECECE'
					}
				},
			},
			yAxis: {
				type: 'value',
				axisLabel: {
					color: '#7A7A7A',
					fontSize: 14,
					rotate: 0,
					formatter: function(value, index) {
						return value + '%';
					}
				},
				axisLine: {
					lineStyle: {
						color: '#CECECE'
					}
				},
			},
			dataZoom: [{
				type: 'inside',
				start: 0,
				end: 100,
			}, {
				start: 0,
				end: 100,
				handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
				handleSize: '80%',
				handleStyle: {
					color: 'rgba(65, 125, 187, 1)'
				}
			}],
			tooltip: {
				trigger: 'axis',
				padding: 0,
				backgroundColor: 'rgba(0,0,0,0)',
				borderRadius: '0',
				formatter: function(params, ticket, callback) {
					var title = params[0].name;
					var num = params[0].data;
					
					if (data.campaign[1] == 'CTR' || data.campaign[1] == 'VTR') {
						title = title + '%';
					} else {
						title = '$' + title;
					}

					var res =
						'<div class="chart-tooltip">'+
							'<div class="is-title">'+ title +'</div>' +
							'<div class="is-content">'+
								'<ul class="is-list">'+
									'<li><span class="is-value">'+ num +'%</span></li>'+
								'</ul>' +
							'</div>' +
						'</div>';
					return res;
				}
			},
			series: [{
				data: Object.keys(sortData).map(function (key) {
					var num = new Number(data.item[key][1]);
					num = num.toFixed(4) * 100;
					num = num.toFixed(2);
					return num;
				}),
				type: 'line',
				smooth: true,
				lineStyle: {
					color: 'rgb(65, 125, 187)',
					opacity: 0.7
				},
				itemStyle: {
					color: 'rgb(65, 125, 187)'
				},
			}]
		});
	}
}
Chart.prototype.generateFunc = function(data) {
	$('.func-category').text(data.category[1] + " > " + data.sub_category[1]);
	$('.func-campaign').text(data.campaign[0] + " " + data.campaign[1]);
	var month;
	switch(this.chartType) {
		case 'month-chart':
			$('#selectYear').val(data.date[0]);
			month = (data.date[1]<10)? '0'+data.date[1]: data.date[1];
			$('#selectMonth').val(month);
			break;
		case 'year-chart':
			month = (data.duration.start[1]<10)? '0'+data.duration.start[1]: data.duration.start[1];
		
			$('.func-title').text(data.tag[1]+ ' 年度趨勢圖表');
			$('.func-tag').text(data.tag[1]);
			$('#selectStart').text(data.duration.start[0]+"/"+month);
			$('#selectEnd').text(data.duration.end[0]+"/"+month);
			break;
		default: break;
	}
}
Chart.prototype.generateForm = function(data) {
	var _this = this;
	var form = $('#searchChartForm');
	var formName = form.attr('name');

	$('[name="category"]').val(data.category[0]);
	$('[name="sub_category"]').val(data.sub_category[0]);
	$('[name="campaign1"]').val(data.campaign[0]);
	$('[name="campaign2"]').val(data.campaign[1]);

	switch(_this.chartType) {
		case 'month-chart':
			break;
		case 'year-chart':
			$('[name="tag"]').val(data.tag[0]);
			break;
		default: break;
	}

	form.find('#datepicker').on('change', function(){
		dateArray = $(this).val().split('/');
		year = dateArray[0];
		month = dateArray[1];
		$('[name="year"]').val(year);
		$('[name="month"]').val(month);
		form.submit();
	});
}
Chart.prototype.changeMetaTitle = function(data) {
	var str = '';
	str = data.category[1] +'>'+ data.sub_category[1] +' '+ data.campaign[0] +' '+ data.campaign[1] + ' ';

	switch(this.chartType) {
		case 'month-chart':
			var month = (data.date[1]<10)? '0'+data.date[1]: data.date[1];
			str += '圖表 ('+ data.date[0] +'/'+ month +')';
			break;
		case 'year-chart':
			var month = (data.duration.start[1]<10)? '0'+data.duration.start[1]: data.duration.start[1];
			str += '年度趨勢圖表 ('+ data.duration.start[0] +'/'+ month +' - '+data.duration.end[0]+'/'+month+')';
			break;
		default: break;
	}

	str += ' - 台北數位指數';
	document.title = str;
}
if (typeof chartData === "object") {
	var chart = new Chart('chart');
	chart.init(chartData);
	
	if (chartData.item.length == 0) {
		$('#chart').html('<h3>No Data</h3>');
	}
	
} else {
	if ($('.func').next().hasClass('chart')) {
		$('.func').hide();
	}
	$('#chart').html('<h3>No Data</h3>');
}
exports.modules = 'Chart';