$('form').each(function() {
	var $this = $(this);
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	$this.on('submit', function(evt) {
		var err = 0;

		switch ($this.attr('name')) {
			case 'contactForm':
				$this.find('input, textarea, select').each(function() {
					var val = $(this).val();
					if (val == '') err += 1;
					var msg = '';
		
					switch ($(this).attr('name')) {
						case 'name': if (!val) msg = '請輸入姓名'; break;
						case 'tel': if (!val) msg = '請輸入電話'; break;
						case 'email':
							if(!val) {
								msg = '請輸入電子信箱';
							} else if (!re.test(val)) {
								msg = '請輸入正確格式電子信箱';
								err += 1;
							}
							break;
						case 'company': if (!val) msg = '請輸入公司名稱'; break;
						case 'p_type': if (!val) msg = '請輸入產業類別'; break;
						case 'comments': if (!val) msg = '請輸入詢問內容'; break;
					}
		
					insertMsg($(this), msg);
				});
				break;

			case 'reportForm':
				var $target = $this.find('[type="email"]');
				var val = $target.val();
				var msg = '';
				if (val == '') err += 1;

				if(!val) {
					msg = '請輸入電子信箱';
				} else if (!re.test(val)) {
					msg = '請輸入正確格式電子信箱';
					err += 1;
				}

				$target.val(msg).addClass('is-error')
					.on('focus', function(){
						$(this).val('').removeClass('is-error');
					});
				break;
		}

		if (err == 0) {
			return true;
		} else {
			return false;
		}
	});
});

function insertMsg(target, msg) {
	if ($(target).next('span.form-error').length) {
		$(target).next('span.form-error').text(msg);
	} else {
		$(target).after('<span class="form-error">'+msg+'</span>');
	}
}

exports.modules = 'formVerify';