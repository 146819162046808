require('bootstrap');
require('./modules/category');
require('./modules/Chart');
require('./modules/datepicker');
require('./modules/formVerify');
require('./modules/hashtagMove');
require('./modules/hud');
require('./modules/preload');
require('./modules/privacy-warning');
require('./modules/query');
require('./modules/results');
require('./modules/selectType');
require('./modules/smartmenusInit.js');
require('./modules/tab');