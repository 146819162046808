var $hud = $('.hud');
var $window = $(window);

$hud
	.hide()
	.scroll();

$window.scroll(function() {
	var $windowScroll = $window.scrollTop();

	if($windowScroll <= 400) {
		if ($hud.length) { $hud.hide(); }
	} else {
		if ($hud.length) { $hud.show(); }
	}
});

exports.modules = 'hud';